<template>
  <main>
    <div id="modalOrdenTrabajo" aria-hidden="true" aria-labelledby="exampleModalLabel" class="modal fade" tabindex="-1">
      <div class="modal-dialog modal-xl modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 id="exampleModalLabel" class="modal-title">
              Registro de Orden de trabajo de laboratorio
            </h5>
            <button aria-label="Close" class="close" type="button" @click="cerrar();">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="form-group col-lg-8 col-md-12 col-sm-12">
                <span class="font-weight-bolder">No. Orden Médica </span>
                <orden-field v-model="orden_clinica" :validator="$v.orden_clinica"></orden-field>
                <div v-if="alerts.length" class="row mt-2">
                  <div class="col-12">
                    <div class="alert alert-danger alert-icon" role="alert">
                      <div class="alert-icon-aside">
                        <i class="fas fa-exclamation-triangle fa-fw"></i>
                      </div>
                      <div class="alert-icon-content py-3">
                        <ul>
                          <li v-for="(alert, i) in alerts" :key="`${i}_alert`">{{ alert }}</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-4">
                <span class="font-weight-bolder">Fecha </span>
                <input v-model="ordentrabajo.fecha" :class="{
                  'is-invalid':
                    $v.ordentrabajo.fecha.$error && $v.ordentrabajo.fecha.$dirty,
                }" class="form-control form-control-sm" type="date"/>
              </div>
              <div class="col-lg-8 col-md-12 col-sm-12 d-block">
                <span class="font-weight-bolder">No. Documento</span>
                <usuario-field v-model="usuario" :showName="true" :validator="$v.usuario"></usuario-field>
              </div>
              <!--  <div class="col-lg-6 col-md-12 col-sm-12 mb-3">
                 <div class="text-center card shadow-none mt-2 card-header-actions">

                   <div class="card-body">
                     <div v-if="Object.keys(usuario).length">
                       <div class="form-group">
                         <strong>{{ usuario.nombre_1 }} {{ usuario.nombre_2 }} {{ usuario.apellido_1 }}
                           {{ usuario.apellido_2 }}</strong>
                         <h6 class="text-body mt-2">FECHA DE NACIMIENTO: {{ usuario.fecha_nacimiento }}</h6>
                         <h6 class="text-body">EDAD : <span class="badge badge-secondary">{{ usuario.years }}</span>
                         </h6>
                       </div>
                     </div>
                     <div class="align-center " v-else>
                       <img class="mb-0" src="@/assets/img-generales/error.png" width="96">
                       <p><strong>Sin datos para mostrar...</strong></p>
                     </div>
                   </div>
                 </div>
               </div> -->
            </div>
            <div class="card shadow-none mt-3">
              <div class="card-header">Areas</div>
              <div class="card-body">
                <div class="row">
                  <div class="col-lg-3">
                    <ul id="cardPillVertical" class="nav nav-pills flex-column" role="tablist">
                      <li v-for="(area, i) in areas_examenes" :key="area.id" class="nav-item">
                        <a :id="`nav_${area.id}`" :class="{ active: i === 0 }" :href="`#tab_${i}`" aria-controls="overview"
                           aria-selected="true" class="nav-link" data-toggle="tab" role="tab">
                          {{ area.nombre }}
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div class="col-lg-9">
                    <div id="cardPillContentVertical" class="tab-content">
                      <div v-for="(item, i) in areas_examenes" :id="`tab_${i}`"
                           :key="`tab_${item.id_area}`"
                           :class="{ 'show active': i === 0 }"
                           aria-labelledby="overview-pill-vertical"
                           class="tab-pane fade pt-2" role="tabpanel"
                      >
                        <div class="">
                          <div class="custom-control custom-checkbox">
                            <input type="checkbox" class="custom-control-input" :id="`area_${item.id_area}`" @change="selectAllOfArea(item.id_area, )">
                            <label class="custom-control-label font-weight-bolder" :for="`area_${item.id_area}`">{{ item.nombre }}</label>
                          </div>
                        </div>
                        <ul class="list-group list-group-flush">
                          <li v-for="examen in item.examenes" :key="examen.id_examen" class="list-group-item pb-1 pt-1">
                            <div class="custom-control custom-checkbox" v-if="!examen.sub_examen.length">
                              <input type="checkbox" v-model="examen.checked" class="custom-control-input" :id="`examen_${examen.id_examen}`" :value="examen.id_examen">
                              <label class="custom-control-label" :for="`examen_${examen.id_examen}`">{{examen.nombre}}</label>
                            </div>
                            <div class="custom-control custom-checkbox" v-if="examen.sub_examen.length">
                              <input type="checkbox" class="custom-control-input" :id="`examen_${examen.id_examen}`" @change="selectAllSub(examen.id_examen)">
                              <label class="custom-control-label" :for="`examen_${examen.id_examen}`">{{examen.nombre}}</label>
                            </div>
                            <!--label v-show="!examen.sub_examen.length" class="switchBtn mb-0">
                              <input v-model="examen.checked" :value="examen.id_examen" type="checkbox">
                              <div class="slide round"></div>
                            </label>
                            <span class="font-weight-bolder"> {{ examen.nombre }}</span-->
                            <ul v-if="examen.sub_examen.length" class="list-group list-group-flush">
                              <li
                                  v-for="subexamen in examen.sub_examen"
                                  :key="subexamen.id_subexamen"
                                  class="list-group-item pb-1 pt-1">
                                <div class="custom-control custom-checkbox">
                                  <input type="checkbox" v-model="subexamen.checked" class="custom-control-input" :id="`examen_${subexamen.id_subexamen}`" :value="subexamen.id_subexamen">
                                  <label class="custom-control-label" :for="`examen_${subexamen.id_subexamen}`">{{subexamen.nombre}}</label>
                                </div>
                                <!--div class="">
                                  <div class="col-lg-12">
                                    <label class="switchBtn mb-0">
                                      <input v-model="subexamen.checked" :value="subexamen.id_subexamen" type="checkbox">
                                      <div class="slide round"></div>
                                    </label>
                                    <span class="form-check-label text-black-20"> {{ subexamen.nombre }}</span>
                                  </div>
                                </div-->
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button class="btn btn-primary btn-sm" type="button" @click="save()">
                <i class="fa fa-save fa-fw"></i>
                Guardar
              </button>
              <button class="btn btn-light btn-sm" type="button" @click="cerrar()">
                <i class="fa fa-times-circle fa-fw"></i>
                Cerrar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>
<script>

import UsuarioField from "../../../../components/common/UsuarioField";
import OrdenField from "../../../../components/common/OrdenField";
import ordenTrabajoService from '../../../../services/ordenTrabajoService';
import AreaLaboratorioService from '../../../../services/AreaLaboratorioService';
import Toast from "../../../../components/common/utilities/toast";
import {required} from "vuelidate/lib/validators";
import $ from "jquery";
import {isEmpty} from "lodash";
import moment from "moment";
import usuarioService from '../../../../services/usuarioService';
import dayjs from "dayjs";
import ordenClinicaService from "../../../../services/ordenClinicaService";
import Swal from "sweetalert2";
export default {
  components: {UsuarioField, OrdenField},
  data() {
    return {
      areas_examenes: [],
      examenes_aplicar: [],
      usuario: {},
      orden_clinica: {},
      ordentrabajo: {
        id: "",
        fecha: dayjs().format("YYYY-MM-DD"),
      },
      type: "store",
      id_cita: null,
    };
  },
  validations() {
    return {
      ordentrabajo: {
        fecha: {required},
      },
    };
  },
  methods: {
    async cargarAreasExamenes() {
      const response = await AreaLaboratorioService.index();
      this.areas_examenes = response.data.map(x => {
        return {
          id_area: x.id,
          allSelected: false,
          nombre: x.nombre,
          examenes: x.examenes.map(e => {
            return {
              id_examen: e.id,
              nombre: e.nombre,
              checked: '',
              allSelected: false,
              sub_examen: e.sub_examen.map(sub => {
                return {
                  id_subexamen: sub.id,
                  nombre: sub.nombre,
                  checked: '',
                }
              })
            }
          }),
        }
      });
    },
    async save() {
      try {
        this.$v.ordentrabajo.$touch();

        if (this.$v.ordentrabajo.$invalid) {
          return;
        }

        this.LoaderSpinnerShow();

        let examenes = [];

        this.areas_examenes.forEach(area => {
          area.examenes.forEach(e => {
            if (e.sub_examen.length) {
              examenes.push(...e.sub_examen.filter(sub => sub.checked).map(x => x.id_subexamen));
            } else {
              e.checked && examenes.push(e.id_examen);
            }
          })
        });

        let datos = {
          ...this.ordentrabajo,
          id_cita: this.id_cita,
          id_usuario: this.usuario.id,
          id_orden: this.orden_clinica ? this.orden_clinica.id : '',
          examenes: examenes
        }

        if (this.type === "store") {
          const {data} = await ordenTrabajoService.store(datos);
          this.type = "store";
          this.LoaderSpinnerHide();
          await Swal.fire("Numero de muestra #"+data.consecutive, "guardado con exito", "success");
          this.$emit("ticket", data.id);
          this.limpiarCampos();
        } else if (this.type === "edit") {
          const {data} = await ordenTrabajoService.update(datos);
          this.limpiarCampos();
          this.type = "store";
          this.LoaderSpinnerHide();
          await Swal.fire("Numero de muestra #"+data.consecutive, "guardado con exito", "success");
        }


        this.$emit("cargar-ordenes");
        this.$emit("saved", datos);

        $("#modalOrdenTrabajo").modal("hide");

      } catch (err) {
        console.error(err);
        this.LoaderSpinnerHide();
        Toast.fire({
          icon: "error",
          title: "Ocurrio un error al procesar la solicitud",
        });
      }
    },
    async limpiarCampos() {
      this.ordentrabajo.fecha = "";
      this.ordentrabajo.id = "";
      this.ordentrabajo.toma_muestra = "";
      this.examenes_aplicar = [];
      this.usuario = {};
      this.orden_clinica = {};
      this.areas_examenes.forEach((area, index) => {
        area.examenes.forEach((examen, i) => {
          this.areas_examenes[index].examenes[i].checked = false;
          if (examen.sub_examen.length) {
            examen.sub_examen.forEach((sub, j) => {
              this.areas_examenes[index].examenes[i].sub_examen[j].checked = false;
            })
          }
        })
      });
    },
    cerrar() {
      this.type = "store";
      this.limpiarCampos();
      this.cargarAreasExamenes();
      $("#modalOrdenTrabajo").modal("hide");
    },
    async edit(id) {

      this.$nextTick(async () => {
        $("#modalOrdenTrabajo").modal("show");
      });

      this.type = "edit";

      const response = await ordenTrabajoService.show(id);
      this.ordentrabajo.id = response.data.id;
      this.usuario = response.data.usuario;
      this.orden_clinica = response.data.orden_clinica;
      this.ordentrabajo.fecha = response.data.fecha;
      this.ordentrabajo.toma_muestra = response.data.toma_muestra;
      this.ordentrabajo.id_cita = response.data.id_cita;

      this.areas_examenes.forEach((area, index) => {
        area.examenes.forEach((examen, i) => {
          let checked = (response.data.detalles.find(x => parseInt(x.id_examen) === parseInt(examen.id_examen))) ? true : false;
          this.areas_examenes[index].examenes[i].checked = checked;
          if (examen.sub_examen.length) {
            examen.sub_examen.forEach((sub, j) => {
              let checked = (response.data.detalles.find(x => parseInt(x.id_examen) === parseInt(sub.id_subexamen))) ? true : false;
              this.areas_examenes[index].examenes[i].sub_examen[j].checked = checked;
            })
          }
        })
      });

      if (response.data.id_orden != null) {
        const {data} = await ordenClinicaService.showOrdenById(response.data.id_orden);
        this.orden_clinica = data;
      }

    },
    open(id_usuario, id_cita, id_orden_medica) {
      this.getUserInfo(id_usuario);
      this.id_cita = id_cita;

      this.$nextTick(async () => {
        $("#modalOrdenTrabajo").modal("show");
        const date_now = dayjs();
        this.ordentrabajo.fecha = date_now.format("YYYY-MM-DD");
        const {data} = await ordenClinicaService.showOrdenById(id_orden_medica);
        this.orden_clinica = data;
      });

    },
    async getUserInfo(id_usuario) {
      const {data} = await usuarioService.show(id_usuario);
      this.usuario = data;
    },
    async selectAllOfArea(areaId){
      let area = this.areas_examenes.find(x => x.id_area === areaId);
      area.examenes.forEach(examen => {
        examen.checked = !area.allSelected;
        examen.sub_examen.forEach(sub => {
          sub.checked = !area.allSelected;
        });
      });
      area.allSelected = !area.allSelected;
    },
    async selectAllSub(examenId){
      let examen = this.areas_examenes.flatMap(x => x.examenes).find(x => x.id_examen === examenId);
      examen.sub_examen.forEach(sub => {
        sub.checked = !examen.allSelected;
      });
      examen.allSelected = !examen.allSelected;
    }
  },
  async created() {
    await this.cargarAreasExamenes();
  },
  computed: {
    edad: function () {
      if (isEmpty(this.usuarios.fecha_nacimiento)) {
        return "";
      }
      return moment()
          .diff(moment(this.usuarios.fecha_nacimiento, "YYYY-MM-DD"), "year")
          .toString();
    },
    contratos_eps: function () {
      if (isEmpty(this.usuarios.eps)) return [];
      return this.usuarios.eps.contrato;
    },
    alerts() {

      let alerts = [];

      if (!isEmpty(this.usuario) && !isEmpty(this.orden_clinica)) {
        if (this.usuario.id !== this.orden_clinica.historia.id_usuario) {
          alerts.push('La orden Ingresada no pertenece al Usuario...');
        }
      }

      return alerts;
    }
  },
};
</script>


<style scoped>
.w-10 {
  width: 10%;
}

.w-5 {
  width: 5%;
}

/*toogle checkbox*/
.switchBtn {
  position: relative;
  display: inline-block;
  width: 35px;
  height: 18px;
}

.switchBtn input {
  display: none;
}

.slide {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
  padding: 8px;
  color: #fff;
}

.slide:before {
  position: absolute;
  content: "";
  height: 12px;
  width: 12px;
  left: 18px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slide {
  background-color: #06a7ed;
  padding-left: 20px;
}

input:focus + .slide {
  box-shadow: 0 0 1px #01aeed;
}

input:checked + .slide:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
  left: -20px;
}

.slide.round {
  border-radius: 24px;
}

.slide.round:before {
  border-radius: 50%;
}
</style>
