<template>
    <main>
        <div class="row">
            <div class="mr-0 pr-0 col-xs-12 col-sm-12 col-md-4 col-lg-4">
                <div class="input-group input-group-sm">
                    <input
                        type="text"
                        class="form-control form-control-sm"
                        :readonly="isSearch"
                        v-on:keyup.enter.prevent="getOrdenById()"
                        v-model="id_orden"
                        :class="classObject"
                    />
                    <div class="input-group-prepend">
                        <span class="input-group-text" id="basic-addon1"><i class="fas fa-search"></i></span>
                      </div>
                </div>
            </div>
            <div class=" col-xs-12 col-sm-12 col-md-8 col-lg-8">
                <div class="card shadow-none card-collapsable">
                    <a class="card-header text-black p-1" href="#collapseFilters" data-toggle="collapse" role="button" aria-expanded="false" aria-controls="collapseFilters">
                        <i class="fa fa-list fa-fw "></i> Detalles de la orden Médica
                        <div class="card-collapsable-arrow">
                            <i class="fas fa-chevron-down"></i>
                        </div>
                    </a>
                    <div class="collapse hide" id="collapseFilters">
                        <ul class="mt-1" v-if="Object.keys(orden_clinica).length">
                            <li style="font-size: 12px;" v-for="item in orden_clinica.items" :key="item.id">
                                {{ item.servicio.descripcion }}
                            </li>
                        </ul>
                        <span v-else class="text-danger mx-2">Sin resultados</span>
                    </div>
                </div>
                <!-- <input
                    type="text"
                    class="form-control form-control-sm"
                    disabled
                    v-model="orden_clinica.id"
                /> -->
            </div>
        </div>
    </main>
</template>

<script>
import ordenClinicaService from "../../services/ordenClinicaService";
import {isEmpty} from 'lodash';

export default {
    props: ["value", "validator"],
    data() {
        return {
            id_orden: "",
            orden_clinica: this.value,
            isSearch: false,
            random_modal_id : '',
            component : '',
        };
    },
    watch: {
        value: {
            handler: function (newValue) {
                this.orden_clinica = newValue;
                if (Object.entries(newValue).length === 0) {
                    this.id_orden = "";
                }
            },
            deep: true,
        },
    },
    methods: {
        async getOrdenById() {
            this.isSearch = true;

            let response = null;

            response = await ordenClinicaService.showOrdenById(this.id_orden);
            
            this.isSearch = false;

            if (!isEmpty(response.data)) {
                this.orden_clinica = response.data;
                this.$emit("input", this.orden_clinica);
            }
            else{
                this.orden_clinica = {};
                this.$emit('input', this.orden_clinica);
            }
        },
        setChange(value) {
            this.$emit("update-value", value);
        },
    },
    computed:{
        classObject: function () {

            let _class = {
                "is-invalid": false,
                "is-valid": false,
            };

            if (this.validator !== undefined && this.validator !== null) {
                _class["is-invalid"] = this.validator.$error;
                _class["is-valid"] = !this.validator.$error && this.validator.$dirty;
            }

            return _class;
            },
    }
};
</script>
<style scoped>
</style>
